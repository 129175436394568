<template>
  <div>
    <b-form-group>
      <template #label>
        <b-form-checkbox
          v-model="all_selected"
          aria-describedby="selected_type"
          aria-controls="selected_type"
          @change="toggleAll"
          :indeterminate="indeterminate"
        >
          Solo contenido seguro
        </b-form-checkbox>
        <p class="mt-1 mb-0 text-filter">Excluir contenido:</p>
      </template>

      <template v-slot="{ ariaDescribedby }">
        <b-form-checkbox-group
          id="selected_type"
          v-model="selected"
          :aria-describedby="ariaDescribedby"
          name="selected_type"
          aria-label="Individual types"
          stacked
        >
          <b-form-checkbox
            v-for="item in selected_type"
            :key="item"
            :value="item"
            class="mt-1"
          >
            {{$t(`negativeContent.${item}`)}}
          </b-form-checkbox>
        </b-form-checkbox-group>
      </template>
    </b-form-group>
  </div>
</template>

<script>
import {
  BFormGroup, BFormCheckboxGroup, BFormCheckbox
} from 'bootstrap-vue'

export default {
  name: 'BrandSafety',
  props: {
    brand_safety: {
      type: Array,
      default: () => []
    }
  },
  components: {
    BFormGroup,
    BFormCheckboxGroup,
    BFormCheckbox
  },
  data () {
    return {
      indeterminate: false,
      all_selected: false,
      selected_type: [
        "toxic",
        "offensive",
        "sentiment_negative",
        "politics",
        "pranks",
        "sex",
        "religion",
        "alcohol",
        "crime",
      ],
      selected: this.brand_safety,
    }
  },
  created () {
  },
  methods: {
    toggleAll(checked) {
      this.selected = checked ? this.selected_type.slice() : []
    }
  },
  watch: {
    selected(newValue) {
      if (newValue.length === 0) {
        this.indeterminate = false
        this.all_selected = false
      } else if (newValue.length === this.selected_type.length) {
        this.indeterminate = false
        this.all_selected = true
      } else {
        this.indeterminate = true
        this.all_selected = false
      }
      this.$emit('changeBrandSafety', this.selected)
    }
  }
}
</script>

<style>
  .text-filter {
    margin-bottom: 14px;
    font-weight: 600;
    color: #718096;
  }
</style>